import request from '@/utils/request'

/**
 * 活动分类相关接口
 * */

// 获取分类列表
export function abstractGetList(params) {
  return request({
    url: '/user/admin/meeting/abstract/getList',
    method: 'GET',
    params,
  })
}

export function abstractSentenceGetList(params) {
  return request({
    url: `/user/admin/meeting/abstract/sentence/getList/${params.scheduleCode}`,
    method: 'GET',
    params,
  })
}

export function abstractAsrGetList(params) {
  return request({
    url: `/user/admin/meeting/abstract/asr/getList/${params.scheduleCode}`,
    method: 'GET',
    params,
  })
}

export function abstractUpdateVoice(data) {
  return request({
    url: '/user/admin/meeting/abstract/updateVoice',
    method: 'POST',
    data,
  })
}

export function abstractGetSummary(params) {
  return request({
    url: `/user/admin/meeting/abstract/summary/get/${params.scheduleCode}`,
    method: 'GET',
    params,
  })
}

export function abstractUpdateSummary(data) {
  return request({
    url: `/user/admin/meeting/abstract/summary/update/${data.scheduleCode}`,
    method: 'POST',
    data,
  })
}

export function updateSentence(data) {
  return request({
    url: '/user/admin/meeting/abstract/sentence/updateSentence',
    method: 'POST',
    data,
  })
}

export function getAbstractWords(params) {
  return request({
    url: `/user/admin/meeting/abstract/words/getList/${params.scheduleCode}`,
    method: 'GET',
    params,
  })
}

export function getAbstractWordsUpdate(data) {
  return request({
    url: '/user/admin/meeting/abstract/words/update',
    method: 'POST',
    data,
  })
}

export function abstractCloseOrOpen(params) {
  return request({
    url: '/user/admin/meeting/abstract/closeOrOpen',
    method: 'GET',
    params,
  })
}

export function getAbstractLink(params) {
  return request({
    url: '/user/admin/meeting/abstract/copyLink',
    method: 'GET',
    params,
  })
}

export function batchOpenAbstract(data) {
  return request({
    url: '/user/admin/meeting/abstract/batchOpen',
    method: 'POST',
    data,
  })
}

export function batchCloseAbstract(data) {
  return request({
    url: '/user/admin/meeting/abstract/batchClose',
    method: 'POST',
    data,
  })
}

/**
 * @description: 清除数据
 * @return {*}
 */
export function abstractClean(params) {
  return request({
    url: `/user/admin/meeting/abstract/clean/${params.scheduleCode}`,
    method: 'GET',
    params,
  })
}

export function getVenueList(params) {
  return request({
    url: '/user/admin/meeting/venue/queryList',
    method: 'GET',
    params,
  })
}

/**
 * @description: 场地保存
 * @param {*} data
 * @return {*}
 */
export function venueSave(data) {
  return request({
    url: '/user/admin/meeting/venue/save',
    method: 'POST',
    data,
  })
}

/**
 * @description: 场地编辑
 * @return {*}
 */

export function venueUpdate(data) {
  return request({
    url: '/user/admin/meeting/venue/update',
    method: 'POST',
    data,
  })
}

/**
 * @description: 查询场地管理员
 * @param {*} params
 * @return {*}
 */
export function venueQueryUserList(params) {
  return request({
    url: '/user/admin/meeting/venue/queryUserList',
    method: 'GET',
    params,
  })
}

/**
 * @description: 添加场地管理员
 * @return {*}
 */

export function venueAddUser(data) {
  return request({
    url: '/user/admin/meeting/venue/addUser',
    method: 'POST',
    data,
  })
}

export function venueDel(data) {
  return request({
    url: '/user/admin/meeting/venue/del',
    method: 'POST',
    data,
  })
}
/**
 * @description: 删除管理员
 * @param {*} params
 * @return {*}
 */
export function venueDelUser(params) {
  return request({
    url: `/user/admin/meeting/venue/delUser/${params.id}`,
    method: 'POST',
    params,
  })
}

/**
 * @description: 查询场地日志
 * @return {*}
 */

export function venueQueryHistoryList(params) {
  return request({
    url: `/user/admin/meeting/venue/queryHistoryList/${params.id}`,
    method: 'GET',
    params,
  })
}
