import request from '@/utils/request'

/**
 * 活动分类相关接口
 * */

// 获取分类列表
export function getTypeList(data) {
  return request({
    url: '/cms/admin/activityType/page',
    method: 'post',
    data,
  })
}

// 新增分类接口
export function saveType(data) {
  return request({
    url: '/cms/admin/activityType/save',
    method: 'post',
    data,
  })
}

// 编辑分类接口
export function editType(data) {
  return request({
    url: '/cms/admin/activityType/update',
    method: 'post',
    data,
  })
}

// 删除分类接口
export function deleteType(params) {
  return request({
    url: '/cms/admin/activityType/delete',
    method: 'delete',
    params,
  })
}

// 获取分类label标签
export function getLabelType(params) {
  return request({
    url: `/cms/admin/activityType/getActivityType`,
    method: 'get',
    params,
  })
}

/**
 * 活动标签相关接口
 * */

// 获取标签列表
export function getTagList(data) {
  return request({
    url: '/cms/admin/tag/page',
    method: 'post',
    data,
  })
}

// 新增标签
export function addTag(data) {
  return request({
    url: '/cms/admin/tag/saveTag',
    method: 'post',
    data,
  })
}

// 修改标签
export function editTag(data) {
  return request({
    url: '/cms/admin/tag/updateTag',
    method: 'post',
    data,
  })
}

// 删除标签
export function deleteTag(params) {
  return request({
    url: '/cms/admin/tag/deleteTag',
    method: 'delete',
    params,
  })
}

// 获取分类label标签
export function getLabelLabel(params, type) {
  return request({
    url: `/cms/admin/tag/getTag/${type}`,
    method: 'get',
    params,
  })
}

/**
 *  活动相关接口
 * */

export function upLoadImg(data) {
  return request({
    url: '/cms/file/upload',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}

export function upLoadAttchments(data) {
  return request({
    url: '/user/file/uploadToB',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}

// 新增活动
export function addActive(data) {
  return request({
    url: '/cms/admin/activityInfo/save',
    method: 'post',
    data,
  })
}

// 获取活动列表

export function activeList(data) {
  return request({
    url: '/cms/admin/activityInfo/page',
    method: 'post',
    data,
  })
}

// 获取活动详情
export function activeDetail(params) {
  return request({
    url: '/cms/admin/activityInfo/get',
    method: 'get',
    params,
  })
}

// 编辑活动
export function editActive(data) {
  return request({
    url: '/cms/admin/activityInfo/update',
    method: 'post',
    data,
  })
}

// 删除活动
export function deleteActive(params) {
  return request({
    url: '/cms/admin/activityInfo/delete',
    method: 'delete',
    params,
  })
}

// 活动门票相关接口

// 保存活动门票
export function saveActiveTicket(data) {
  return request({
    url: '/cms/admin/activityInfo/saveActivityTicket',
    method: 'post',
    data,
  })
}

// 编辑活动门票
export function editActiveTicket(data) {
  return request({
    url: '/cms/admin/activityInfo/updateActivityTicket',
    method: 'post',
    data,
  })
}

// 获取活动门票列表
export function getActiveTicket(params) {
  return request({
    url: '/cms/admin/activityInfo/getActivityTicket',
    method: 'get',
    params,
  })
}

// 删除活动门票列表

export function delActiveTicket(params) {
  return request({
    url: '/cms/admin/activityInfo/deleteActivityTicket',
    method: 'delete',
    params,
  })
}
